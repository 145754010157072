<template>
  <div>
    <loading 
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>
    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{error_message}}</CAlert>
    </div>
    <b-row>
      <b-col>
        <h3>Pago de paseadores</h3>
      </b-col>
    </b-row>

    <section>
      <b-row>
        <b-col lg="4">
          <CCard>
            <CCardHeader>
              <strong>Filtros</strong>
              <div class="card-header-actions">
                <b-button @click="fetchWalkers">Consultar paseadores</b-button>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <b-form-group>
                    <small>Ciudades</small>
                    <v-select
                      v-model="citySelected"
                      :options="cities"
                      placeholder="Selecciona tu ciudad"
                      :class="{'form-control is-invalid': $v.citySelected.$error,
                                      'form-control is-valid': !$v.citySelected.$invalid}"
                    ></v-select>
                    <b-form-invalid-feedback
                      v-if="!$v.citySelected.required"
                    >Debes seleccionar una ciudad</b-form-invalid-feedback>
                  </b-form-group>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>  
        </b-col>
        <b-col lg="8">
          <CCard>
            <CCardHeader>
              <strong>Calcular pago</strong>
              <div class="card-header-actions">
                <b-button 
                  @click="submit"
                  :disabled="!walkers.length"
                >Calcular</b-button>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="6">
                  <small>Paseador</small>
                  <v-select
                    :options="walkers"
                    v-model="walkerSelected"
                    placeholder="Selecciona"
                    :disabled="!walkers.length"
                    :class="{
                      'form-control is-invalid': $v.walkerSelected.$error,
                      'form-control is-valid': !$v.walkerSelected.$invalid
                    }"
                  >
                  </v-select>
                </CCol>

                <CCol sm="12" lg="6">
                  <b-form-group>
                    Fecha: {{date}}
                    <b-form-datepicker
                      id="date"
                      placeholder="mm/yyyy"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'long'
                      }"
                      locale="es-ES"
                      v-model="date"
                      :class="{
                        'form-control is-invalid': $v.date.$error,
                        'form-control is-valid': !$v.date.$invalid
                      }"
                      v-bind="labels['es-ES'] || {}"
                      today-button
                      reset-button
                      :show-decade-nav="true"
                      calendar-width="300px"
                      :date-disabled-fn="dateDisabled"
                    ></b-form-datepicker>
                  </b-form-group>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>  
        </b-col>

        <!-- table -->
        <b-col lg="12">
          <CCard>
            <CCardHeader>
              <strong>Detalle de pago</strong>
              <div class="card-header-actions">
                <b-button 
                  @click="exportToExcel"
                  :disabled="!items.length"
                >Exporta excel</b-button>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    stacked="md"
                    show-empty
                    small
                    fixed                
                    hover
                    bordered
                    @filtered="onFiltered">                
                  </b-table>
                </CCol>

              </CRow>
            </CCardBody>
          </CCard>  
        </b-col>
      </b-row>      
    </section>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import Loading from 'vue-loading-overlay';
import * as XLSX from 'xlsx'

moment.locale("es");
const OWNER_ID = 1
const ADMIN_ID = 2;
export default {
  name: "WalkerPaymentCalculator",
  components: {
    Loading,
  },
  data () {
    return {
      loading: true,
      errors: [],
      date: null,
      labels: {
        'es-ES': {            
          labelPrevYear: 'Año anterior',
          labelPrevMonth: 'Mes anterior',
          labelCurrentMonth: 'Mes actual',
          labelNextMonth: 'Siguiente mes',
          labelNextYear:'Siguiente año',
          labelTodayButton: 'Hoy',
          labelResetButton: 'Limpiar',
          labelHelp: 'Usa el cursor para navegar en las fechas'
        }
      },
      cities: [],
      citySelected: {},
      walkers: [],
      walkerSelected: null,
      items: [],
      fields: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 200,
      pageOptions: [10, 20, 40, { value: 120, text: "Muestra muchas" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [], 
    }
  },
  validations: {
    date: {
      required
    },
    citySelected: {
      required
    },
    walkerSelected: {
      required,
    },
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    let access = this.userProfile.acceso.id
    this.date = moment(new Date()).format("YYYY-MM-")+'01';

    if (access ==  OWNER_ID || access == ADMIN_ID) {
      this.fetchCities();
    } else {
      this.flagErr = true
      this.errMsg = 'No posees los permisos para este módulo'
      this.loading = false
    }

  },
  methods: {
    exportToExcel() {
      // Convertir los datos de la tabla a formato de hoja de cálculo
      const ws = XLSX.utils.json_to_sheet(this.items)

      // Ajustar el ancho de las columnas (opcional)
      const wscols = [
        { wch: 10 }, // ancho para la primera columna
        { wch: 10 }, // ancho para la segunda columna
        // ... ajusta según tus columnas
      ]
      ws['!cols'] = wscols

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Pagos')

      // Generar el archivo y descargarlo
      const joinName = this.walkerSelected.label.split(' ').join('_')
      const fileName = `pagos_${joinName}_${moment(this.date).format('YYYY-MMM')}.xlsx`
      XLSX.writeFile(wb, fileName)
    },
    dateDisabled(ymd, date) {
      return date.getDate() !== 1
    },
    fetchCities() {
      this.loading = true
      let getCities = this.$firebase.functions().httpsCallable('getCities')
      getCities().then((response) => {
        if(response.data != null) {
          let cities = response.data.data
          if(this.userProfile.acceso.id == OWNER_ID) {
            cities.map((city) => {            
              this.cities.push({
                value: city.id,
                label: city.nombre,
              })
            })
          } else if (this.userProfile.acceso.id == ADMIN_ID) {
            let cityFound = cities.find(city => city.nombre == this.userProfile.ciudad)            
            this.citySelected  = {
              value: cityFound.id,
              label: cityFound.nombre
            } 
            this.cities = [{
              value: cityFound.id,
              label: cityFound.nombre
            } ]

          }
        }        
      })
      .catch(error => console.log(error))
      .finally(()=>{
        this.loading = false
      })
    },
    // submit() {
    //   this.loading = true
    //   const date = moment(this.date).format('YYYY-MM-')+'01T00:00:00.000Z'
    //   const lastDayOfMonth = moment(this.date)
    //       .endOf('month')
    //       .format('YYYY-MM-DD') + 'T23:59:59.290Z';
    //   let payload = {
    //     startDate: date,
    //     endDate: lastDayOfMonth,
    //     city: this.citySelected,       
    //   };
    //   console.log(payload)

    //   const fxName = this.version == 1 ? 'financialMonthlyKPI' : 'financialMonthlyKPIPermanent'

    //   let getFinancialKPIs = this.$firebase.functions().httpsCallable(fxName)

    //   getFinancialKPIs(payload).then(response => {
    //     this.loading = false
    //     console.log(response)
    //     let {
    //       totalMoneyInPayments,
    //       totalMoneyInAccounts,
    //       moneyInAccounts,
    //       virtualAccountsMoney,
    //       moneyAccumulated,
    //       totalSpentMoney,
    //       spendings,
    //       pendingIncomes,
    //       servicesIncomes,
    //       servicesDebts,
    //       totalVirtualAccountsMoney,
    //     } = response.data

        
    //     this.totalMoneyInAccounts = totalMoneyInAccounts
    //     this.totalMoneyInPayments = totalMoneyInPayments
    //     this.moneyInAccounts = moneyInAccounts
    //     this.virtualAccountsMoney = virtualAccountsMoney
    //     this.moneyAccumulated = moneyAccumulated
    //     this.totalSpentMoney = totalSpentMoney
    //     this.spendings = spendings
    //     this.pendingIncomes = pendingIncomes
    //     this.servicesIncomes = servicesIncomes
    //     this.servicesDebts = servicesDebts
    //     this.totalVirtualAccountsMoney = totalVirtualAccountsMoney

    //   }).catch(error => {
    //     console.log(error)
    //   })
    // },
    submit() {
      this.loading = true

      let payload = {
        dateYM: moment(this.date).format('YYYY-MM'),
        userId: this.walkerSelected.value,
      };
      console.log(payload)

      let walkerPaymentCalculator = this.$firebase.functions().httpsCallable('walkerPaymentCalculator')

      walkerPaymentCalculator(payload).then(response => {
        this.loading = false
        const { fields, items } = response.data
        this.fields = fields
        this.items = items

      }).catch(error => {
        console.log(error)
      })
    },
    fetchWalkers(){
      let payload = {
        value: this.citySelected.value,
      }
      this.$store.dispatch("fetchWalkersByCityId", payload).then(walkers => {
        this.walkers = []
        walkers.map(walker => {
          this.walkers.push({
            value: walker.id,
            label: walker.fullname
          })
        });

      }).catch(() => {
        console.log('Err fetching walkers')
      })
    },
  }
};
</script>

<style lang="scss" scoped></style>
